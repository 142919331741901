<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">Consider the reaction shown below:</p>

      <p class="pl-8 mb-6">
        <chemical-latex content="Cu^2+(aq) + 4 NH3(aq) -> [Cu(NH3)4]^2+(aq)" />
      </p>

      <p class="mb-n3">a) Which species in the reaction is the Lewis acid?</p>

      <v-radio-group v-model="inputs.lewisAcid" :disabled="!allowEditing" class="mb-0 pl-6">
        <v-radio
          v-for="option in options"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">b) Which species in the reaction is the Lewis base?</p>

      <v-radio-group v-model="inputs.lewisBase" :disabled="!allowEditing" class="mb-0 pl-6">
        <v-radio
          v-for="option in options"
          :key="'pt-2-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'Question505',
  components: {CalculationInput, ChemicalLatex, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        lewisAcid: null,
        lewisBase: null,
      },
      options: [
        {text: '$\\ce{Cu^2+(aq)}$', value: 'Cu'},
        {text: '$\\ce{NH3(aq)}$', value: 'NH3'},
        {text: '$\\ce{[Cu(NH3)4]^2+(aq)}$', value: 'CuNH3'},
        {text: '$\\text{There is none}$', value: 'none'},
      ],
    };
  },
};
</script>
<style scoped></style>
